<template>
  <div class="overlay">
  <div class="container" @click="catT()">
    <slot></slot>
  </div></div>
</template>

<script>
export default {
    name: 'Overlay',
    props: {
        title: String,
    },
    data() {
        return {
            btnStart: false
        }
    },
    methods: {
        catT() {
            this.$emit('btnClose')
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>
