<template>
  <article class="rule">
    <dl class="tab">
        <dd @click="cowT('set')" class="TBset"></dd>
        <dd @click="cowT('note')" class="TBnote"></dd>
        <dd @click="cowT('rule')" class="TBrule"></dd>
        <dt @click="cowT('msg')" class="TBcallback"></dt>
    </dl>
    <div>
        <h4>请输入反馈内容：</h4>
        <p><textarea name="" id="" cols="30" rows="10"></textarea></p>
        <q><b @click="cowT('send')" class="button send"></b></q>
    </div>
  </article>
</template>

<script>
export default {
    name: 'Level',
    data() {
        return {
            msg: [
            ]
        }
    },
    methods: {
        cowT(dog) {
            this.$emit('bull', dog)
        },
    }
}
</script>

