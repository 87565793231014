<template>
  <div id="app">
    <Loading v-if="showSW === 'loading'" :loadcaps="resources" />
    <Home  :users="users"     :playing="playing" :startsw="startSW"    v-if="showSW === 'home'" @dog="homeSW" />
    <Cards :userids="userIDs" :playing="playing" :Cards  ="Qins" :firstUser="theFirst" :soundSW="soundSW" :speed="speedSW" @toyou="thisPlayer" @WCardShow="ModalWCard" @gameover="theEnd" v-if="startSW" />
    <Panel :users="users"     :playing="playing" :startsw="startSW"    v-if="showSW === 'panel'" @dog="startDHD" @chicken="ModalToolbar" />
    <End   :users="users"     :playing="playing" :scores ="overScores" v-if="showSW === 'end'"   @dog="endPageSW" />
    <Page  :pageData="page" v-if="showSW === 'page'" @dog="pageSW" @bird="bird" />
    <Overlay v-if="ModalSW.state" @btnClose="btnOver">
        <div v-if="ModalSW.type == 'logout'" class="cardModalBtn"><b class="button continueT" @click="ModalClose"></b><b class="button quit" @click="showHome"></b></div>
        <ModalWCards v-if="ModalSW.type == 'ModalWCards'" :MBody="ModalSW.body"></ModalWCards>
        <b v-if="ModalSW.btn" class="button close" @click="ModalClose"></b>
    </Overlay>
  </div>
</template>
<script>
import Cards         from './components/cards.vue'
import Panel         from './components/panel.vue'
import Loading       from './components/loading.vue'
import End           from './components/end.vue'
import Page          from './components/page.vue'
import Home          from './components/home.vue'
import Overlay       from './components/overlay.vue'
import ModalWCards   from './components/modalWCards.vue'
import wx            from 'weixin-js-sdk'
export default {
    name: 'App',
    components: {
        Cards, Panel, Loading, End, Home, Overlay, ModalWCards, Page
    },
    data() {
        return {
            users: [],
            theFirst: '',           //记录本盘里由谁出牌
            playing: '',            //记录现在由谁该或正在出牌
            userIDs: [],
            Qins: [],
            apiUrl: process.env.VUE_APP_BASE_URL,
            resources: {
                Rtotal: 7,
                Rsize: 0,
                pics: [
                    require("@/assets/X1/boardBg.jpg"),   // 一般页面背景
                    require("@/assets/X1/boardWin.jpg"),  // 赢的页面背景
                    require("@/assets/X1/boardLoss.jpg"), // 输的页面背景
                    require("@/assets/X1/boardPan.jpg"),  // 游戏中的页面背景
                    require("@/assets/X1/dhdTitle.png"),  // 游戏中的标题背景
                    require("@/assets/X1/medal.png"),     // 游戏中的勋章
                    require("@/assets/X1/dashboard.jpg"), // 首页背景图
                    require("@/assets/X1/modalBg.jpg"),   // modal背景图
                    require("@/assets/X1/btnimg.png")     // 游戏中的按钮背景
                ],
            },
            page: {type: '', body: Object, form: ''},
            // apiUrl: 'https://api.wagoz.cn/api/',
            showSW: 'loading',       // 显示哪个界面 panel cards loading
            endSW: false,
            ModalSW: {state: false, btn: false, overClose: false, type: '', body: Object},
            startSW: false,
            WCardList: [],           // 查看被钓过的牌
            overScores: {Pscore: 0, Mscore: 0, Nscore: 0, state: ''},
            balanceScores: [],
            SoundTheEnd: new Audio('/audio/theEnd.wav'),
            SoundBtnStart: new Audio('/audio/btnStart.mp3')
        }
    },
    methods: {
        // 普通页面
        pageTo(dog) {
            this.showSW    = 'page'
            this.page.type = dog
            this.page.form = 'home'
            this.page.body = {}
            if (dog == 'set') {
                this.page.body = this.users[1].options
            } else if (dog == 'rank') {
                this.page.body.api = this.apiUrl
            }
        },
        pageSW() {
            this.showHome()
        },
        // 处理来自 page>set 的保存操作
        bird(wasp) {
            this.axios({
                method: 'post',
                url: this.apiUrl + 'setting',
                data: {uid: this.users[1]._id, ops: wasp},
                headers: {
                    'content-type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(res => {
                this.users.splice(1, 1, res.data)
            }).catch(err=>{
                console.log(err)
          })
        },
        // 轮下一个
        thisPlayer() {
            let theFirst = this.theFirst,
                playing  = this.playing,
                userids  = this.userIDs
            if(playing) {
                let playInt = userids.findIndex(item => item == playing)
                if(playInt < 2) {
                    this.playing = userids[playInt + 1]
                } else {
                    this.playing = userids[0]
                }
            } else {
                this.playing = theFirst
            }
        },
        homeSW(dog) {
            if (dog == 'start') {
                this.startDHD()
            } else {
                this.pageTo(dog)
            }
        },
        endPageSW(dog) {
            if(dog == 'R') {
                this.startDHD()
            } else {
                this.showHome()
            }
        },
        startDHD() {
            if(this.soundSW) {
                this.SoundBtnStart.play()
            }
            this.axios({
                method: 'get',
                url: this.apiUrl + 'qins',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(res => {
                this.Qins    = res.data.qins
                this.showSW  = 'panel'
                this.startSW = true
            }).catch(err=>{
                console.log(err)
            })
        },
        showLoading() {
            this.showSW    = 'loading'
        },
        // 显示首页，对 ModalSW \ page 对象进行初始化
        showHome() {
            this.showSW    = 'home'
            this.startSW   = false
            this.page      = {type: '', body: Object, form: ''}
            this.ModalClose()
        },
        ModalClose() {
            this.ModalSW = { state: false, btn: false, overClose: false, type: '', body: Object }
        },
        btnOver() {
            if(this.ModalSW.overClose) {
                this.ModalClose()
            }
        },
        ModalToolbar(spot) {
            this.ModalSW.state = true
            if(spot == 'Q') {
                this.ModalSW.type = 'logout'
            } else {
                this.ModalSW.type = 'set'
                this.ModalSW.btn = true
            }
        },
        // 展示底牌或被钓走的牌
        ModalWCard(WDs, Cown) {
            let note
            if (Cown == 'DC') {
                if (WDs.length) {
                    note = "MDc"
                } else {
                    note = "你唔是尾家，不能看底牌！"
                }
            } else {
                let Aown = Cown.replace('-W', '')
                let u = this.users.findIndex(item => item._id == Aown)
                switch (u) {
                    case 0:
                        note = 'PWc'
                        break;
                    case 1:
                        note = 'MWc'
                        break;
                    case 2:
                        note = 'NWc'
                        break;
                }
                if (!WDs.length) {
                    note = `${this.users[u].nickName} 钓无牌！`
                }
            }
            this.ModalSW.state = true
            this.ModalSW.btn = true
            this.ModalSW.overClose = true
            this.ModalSW.type = 'ModalWCards'
            this.ModalSW.body = {
                list: WDs,
                note
            }
        },
        theEnd(scores) {
            this.endSW      = true
            this.startSW    = false
            this.showSW     = 'end'
            this.playing    = '' 
            let users       = this.users,
                userids     = this.userIDs,
                theFirst    = this.theFirst,
                playInt     = userids.findIndex(item => item == theFirst)
            if(playInt < 2) {
                this.theFirst = userids[playInt + 1]
            } else {
                this.theFirst = userids[0]
            }
            if(this.soundSW) {
                this.SoundTheEnd.play()
            }
            this.ModalClose()
            this.axios({
                method: 'post',
                url: this.apiUrl + 'scores',
                data: {user: users[1], score: scores.Mscore},
                headers: {
                    'content-type': 'application/json',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(res => {
                let NGrade = res.data.grade.Lv,
                    LvState = ''
                if (users[1].grade) {
                    let OGrade = users[1].grade.Lv
                    if (OGrade < NGrade) {
                        LvState = 'upgrade'
                    }
                } else {
                    if (NGrade != 1) {
                        LvState = 'upgrade'
                    }
                }
                scores.state = LvState
                this.overScores = scores
                this.users.splice(1, 1, res.data)
            }).catch(err=>{
                if (err.response.status === 401) {
                    wx.miniProgram.navigateTo({
                        url: '/pages/index/index',
                        success: function(e){
                            console.log('web-view页面跳转小程序success ', e)
                        },
                        fail: function(e){
                            console.log('web-view页面跳转小程序fail    ', e)
                        },
                        complete: function(e){
                            console.log('web-view页面跳转小程序complete', e)
                        }
                    })
                    localStorage.removeItem('token')
                }
          })
        },
        getUser() {
            this.axios({
                method: 'get',
                url: this.apiUrl + 'users',
            }).then(res => {
                this.users   = res.data.users
                this.theFirst = this.users[1]._id
                this.userIDs = this.users.map(item => item._id)
                let Rtotal = this.resources.Rtotal

                for (let pic of this.resources.pics) {
                    let pbg = new Image()
                    pbg.src = pic
                    pbg.onload = () => {
                        this.resources.Rsize ++
                        if(Rtotal == this.resources.Rsize) {
                            this.showSW  = 'home'
                        }
                    }
                }
            }).catch(err=>{
                console.log(err)
                if (err.response.status === 401) {
                    wx.miniProgram.navigateTo({
                        url: '/pages/index/index',
                        success: function(e){
                        console.log('web-view页面跳转小程序success',e)
                        },
                        fail: function(e){
                        console.log('web-view页面跳转小程序fail',e);
                        },
                        complete:function(e){
                        console.log('web-view页面跳转小程序complete',e);
                        }
                    })
                    localStorage.removeItem('token')
                }
          })
        },
    },
    computed: {
        soundSW() {
            let sound
            if(typeof this.users[1].options !== 'undefined') {
                sound = this.users[1].options.sound
            } else {
                sound = false
            }
            return sound
        },
        speedSW: function() {
            let speed
            if(typeof this.users[1].options !== 'undefined') {
                speed = this.users[1].options.speed
            } else {
                speed = 1000
            }
            return speed
        }
    },
    created() {
        let search = window.location.search
        let token = search.slice(7)
        // let token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyTVNHIjp7Im5pY2tOYW1lIjoi56C05qOL5a2QIiwiZ2VuZGVyIjoiMCIsImxhbmd1YWdlIjoiemhfQ04iLCJjaXR5IjoiIiwicHJvdmluY2UiOiIiLCJjb3VudHJ5IjoiIiwiYXZhdGFyVXJsIjoiaHR0cHM6Ly90aGlyZHd4LnFsb2dvLmNuL21tb3Blbi92aV8zMi9RMGo0VHdHVGZUS0VINjlrUkxxdURHS0l3VHgwZ3V3NkM2SG1MM3NMZDFDRmtHOWljdENpYXVHSDN3THhhS1FnR3g5MWljMXlXVFZWeGdUOWNqODdhZFJ2QS8xMzIiLCJfaWQiOiI2MjZmZTRjNDMxNWY1YzNmNjQzODdlZjQifSwiaWF0IjoxNjcyNDc2MzIzLCJleHAiOjE2NzI1NjI3MjN9.ZBxKGjlwNC5iK0djg2Le6BMswFNuLYpNNx6hhdI2n7A'
        localStorage.setItem('token', token)
    },
    mounted() {
        this.axios.interceptors.request.use(config => {
            if(localStorage.getItem('token')){
                config.headers.common['authorization'] = 'Bearer ' + localStorage.getItem('token');
            }
            return config;
        }, error => {
            return Promise.reject(error);
        });
        this.getUser()
    },
}
window.onload = function () {

  document.addEventListener('touchstart', function (event) {
    if (event.touches.length > 1) {
      event.preventDefault()
    }
  })
  document.addEventListener('gesturestart', function (event) {
    event.preventDefault()
  })
}
</script>

<style lang="stylus">
#app
    font-family: Avenir, Helvetica, Arial, sans-serif
    -webkit-font-smoothing: antialiased
    -moz-osx-font-smoothing: grayscale
    text-align: center
    color: #2c3e50
    background-size: contain
    overflow: hidden
</style>