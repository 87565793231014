<template>
    <section class="desk greenMan">
        <pre></pre>
        <TransitionGroup name="DC" tag="ul" class="DCards"><Card v-for="(item, index) in DCards" :key='"DC" + index' :Card="item" @sendcard="Whale" /></TransitionGroup>
        <div class="WArea">
            <ul class="WCards" v-if="WCards.length"><Card v-for="(item, index) in WCards" :key='"W" + index' :Card="item" /></ul>
            <ul class="TCard" v-if="TCard.length"><Card v-for="(item, index) in TCard" :key='"T" + index' :Card="item" /></ul>
        </div>
        <ul class="MCards" v-if="MCards.length" :class="optionLock? 'active': 'disable'"><Card v-for="(item, index) in MCards" @sendcard="Tiger" :key='"M" + index' :checked="Bide" :Card="item" /></ul>
        <ul class="MWCards"><Card v-for="(item, index) in MWCards" :key='"MW" + index' :Card="item" @sendcard="Whale" /></ul>
        <TransitionGroup name="PC" tag="ul" class="PCards"><Card  v-for="(item, index) in PCards"  :key='"P" +  index' :Card="item" /></TransitionGroup>
        <TransitionGroup name="NC" tag="ul" class="NCards"><Card  v-for="(item, index) in NCards"  :key='"N" +  index' :Card="item" /></TransitionGroup>
    </section>
</template>

<script>
import Card from './card.vue'
export default {
    name: 'Cards',
    props: {
        Cards: {
            type: Array
        },
        userids: {
            type: Array
        },
          playing: String,
        firstUser: String,
            speed: Number,
          soundSW: Boolean
    },
    components: {
        Card
    },
    data() {
        return {
            start: false,                //“发牌”未完成不能开
            optionLock: false,           //出牌操作开关
            lastNum: Number,             //最后一被钓走的牌的数字，记录已钓牌的顺序
            Bide: '',                    //记录M牌的 选中 效果
            delay: 1000,                  //设置电脑出牌时间 —— 计算里
            sound: {
                type: false,                                     //设置声音是否启动
                audioChu: new Audio('/audio/chupaiX.mp3'),       //出牌声音
                audioFuan: new Audio('/audio/fuanX.mp3'),        //翻牌声音
                audioFa: new Audio('/audio/fapai.mp3'),          //发牌声音
                audioClick: new Audio('/audio/click03.mp3'),     //选中牌声音
                audioDiao: new Audio('/audio/diao00.wav'),       //钓到牌声音
                audioDiaoWin: new Audio('/audio/diao01.wav'),    //钓双红牌声音
                audioDiaoOut: new Audio('/audio/diao03.wav'),    //钓不到牌声音
            }
        }
    },
    watch: {
        playing:  {
            immediate: true,
            deep: true,
            handler () {
                let playerId = this.playing
                if (RegExp('^AIsg').test(playerId)) {
                    this.AIfunction()
                } else {
                    this.optionLock = true
                }
            }
        }
    },
    methods: {
        cFilter: function (Acards, X) {
            return Acards.filter((item) => {
                return item.own == X
            })
        },
        compare: (property) => {
            return (a,b) => {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
            }
        },
        shuffle: (arr) => {
            var i, j, temp;
            for (i = arr.length - 1; i > 0; i--) {
                j = Math.floor(Math.random() * (i + 1));
                temp = arr[i];
                arr[i] = arr[j];
                arr[j] = temp;
            }
            return arr;
        },
        // 正常整理牌，大王、小王、A、K……
        sortOut: function() {
            return (a, b) => {
                let   red = ['A', 'C', 'Z']
                let black = ['B', 'D', 'V']
                // A 应该排在 K 前面，权重改为 14
                function addTen(number) {
                    if (number === 1) {
                        number = 14
                    }
                    return number
                }
                let  Ades = a.des, Bdes = b.des
                let  Anum = a.num, Bnum = b.num
                let   res = addTen(Bnum) - addTen(Anum)
                // 数字相同 && 前红、后黑
                if (res === 0 && red.includes(Ades) && black.includes(Bdes)) {
                    res = -1
                }
                return res
            }
        },
        // 数组对象方法排序:true 为正序，false 为反序
        sortByKey(array, key, point) {
            return array.sort(function(a, b) {
                let x = a[key], y = b[key], Z
                if(point) {
                    Z = (y < x) ? -1 : ((y > x) ? 1 : 0)
                } else {
                    Z = (x < y) ? -1 : ((x > y) ? 1 : 0)
                }
                return Z;
            });
        },
        // 算单牌价值总得分
        Cat(Acard, Xpot) {
            let Anum = Acard.num, Ascore = Acard.score, totalScore = ''

            if (Anum == 21 || Anum == 20) {
                totalScore = 30
            } else {
                if (Anum < 10 && Anum != 5) {
                    Anum = 10 - Anum
                    totalScore = Ascore * 4
                } else {
                    totalScore = Ascore * 3
                }
                let Acards = Xpot.filter(item => item.num == Anum)
                let Aindex = Acards.indexOf(Acard)
                Acards.splice(Aindex, 1)
                for (let AC of Acards) {
                    totalScore = Number(totalScore) + AC.score
                }
            }
            return totalScore
        },
        // 算单牌在某个池里的分数
        Dog(Anum, Xpot) {
            let totalScore = ''

            if (Anum == 21 || Anum == 20) {
                totalScore = 30
            } else {
                let Acards = Xpot.filter(item => item.num == Anum)
                for (let AC of Acards) {
                    totalScore = Number(totalScore) + AC.score
                }
            }
            return totalScore
        },
        // 确定牌
        Duck(num) {
            let playerID = this.playing
            let DiaoNum = this.toDiaoNum(num)
            let PlayerCards = this.Cards.filter(item => item._id == playerID)
            let DuckWCards = this.WCards.filter(item => item.num == DiaoNum)
            let DuckMCards = PlayerCards.filter(item => item.num == DiaoNum)
            // let DuckCards = DuckWCards.concat(DuckMCards)
            let DuckCards = [...DuckWCards, ...DuckMCards]
            let Anum = this.Cat(num, DuckCards)    // 自己牌与白牌单牌总价值
            let Bnum = this.Cat(num, this.Cards)   // 所有池里的单牌总分

            if(Anum == Bnum) {
                return true
            }
        },
        // 打开蒙层显示已经钓到的牌
        Whale(Cid) {
            let userids = this.userids
            let PANuserids = this.PANuserids
            let Acap = this.Cards.find(item => item._id == Cid), CardOwn, WDs = []
            if (RegExp('^AIsg').test(Acap.own)) {
                CardOwn = Acap.own + '-W'
            } else {
                CardOwn = Acap.own
            }
            // 判断是否看底牌
            if (Acap.own == "DC") {
                if (userids[1] == PANuserids[2]) {
                    let WDsT = []
                    WDsT.push(this.DCards[0])
                    WDs = JSON.parse(JSON.stringify(WDsT))
                    WDs[0].open = true
                }
            } else {
                WDs = this.sortByKey(this.Cards.filter(item => item.own == CardOwn), 'sort', true)
            }
            this.$emit('WCardShow', WDs, CardOwn)
        },
        // 设置声音
        Jue() {
            // let Cards = this.Cards
            this.sound.type = this.soundSW
        },
        // playMusic(type) {
        //     this.$refs.audio.currentTime = 0;//从头开始播放
        //     this.$refs.audio.play(); //播放
        // },
        // 出牌动作(出牌、钓牌、翻牌、钓牌)
        Tiger(Tid) {
            let Bid = this.Bide
            if(Tid == Bid) {
                if(this.sound.type) {
                    this.sound.audioChu.play()
                }
                this.Bide = ''
                if(this.optionLock && this.start) {
                    this.optionLock = false
                    this.Lion(Tid)
                }
            } else {
                if(this.sound.type) {
                    this.sound.audioClick.play()
                }
                this.Bide = Tid
            }
        },
        // 对出牌的整个动作进行分解
        Lion(Lid) {
            let Delay     = this.speed
            if (Delay == null) {
                Delay = 1000
            }
            let LionCard  = this.Cards.find(item => item._id == Lid)
            LionCard.own  = 'TCard'
            LionCard.open = true
            setTimeout(() => {
                this.Diao()
            }, Delay);
            setTimeout(() => {
                this.Fuan()
            }, Delay * 2);
            setTimeout(() => {
                this.Diao()
            }, Delay * 3);
            setTimeout(() => {
                this.NextPlayer()
            }, Delay * 4);
        },
        // 结束后，返回分数
        Pig() {
            let Pscore = 0, Mscore = 0, Nscore = 0
            let that = this
            let Pid = that.userids[0], Mid = that.userids[1], Nid = that.userids[2]
            for (let item of this.Cards) {
                if(item.des === "A" || item.des == "C" || item.des == "Z") {
                    switch (item.own) {
                        case Pid + "-W":
                            Pscore = Pscore + that.Fox(item.num)
                            break;
                        case Mid + "-W":
                            Mscore = Mscore + that.Fox(item.num)
                            break;
                        case Nid + "-W":
                            Nscore = Nscore + that.Fox(item.num)
                            break;
                    }
                }
            }
            Pscore = Pscore - 70
            Mscore = Mscore - 70
            Nscore = Nscore - 70
            return {Pscore, Mscore, Nscore}
        },
        // 根据 num 算分
        Fox(num) {
            let score = 0
            if((num > 8 && num != 20) || num == 5 || num == 1) {
                score = 10
            } else {
                score = num
            }
            return score
        },
        // 桌面上有剩余的牌（大小王、或四个公仔）
        Zebra() {
            let count = this.WCards.length
            if(count == 4 || count == 2) {
                for (let card of this.WCards) {
                    card.own = this.playing + '-W'
                }
            }
        },
        // 转到下家
        NextPlayer() {
            if(this.DCards.length) {
                this.$emit('toyou')
            } else {
                this.Zebra()
                let endScores = this.Pig()
                this.$emit('gameover', endScores)
            }
        },
        // 发牌
        wolf() {
            let i = 54
            let ol = 53
            let OCards = this.Cards
            let userids = this.userids
            let PANuserids = this.PANuserids
            let FPJSQ = setInterval(() => {
                i --
                if (i >= 30) {
                    if (i == ol) {
                        OCards[i].own = PANuserids[0]
                    } else if (i == ol - 1) {
                        OCards[i].own = PANuserids[1]
                    } else if (i == ol - 2) {
                        OCards[i].own = PANuserids[2]
                        ol = i - 1
                    }
                    if (OCards[i].own == userids[1]) {
                        OCards[i].open = true
                    }
                } else if (i < 30 && i > 23) {
                    OCards[i].open = true;
                    OCards[i].own = 'W';
                    OCards[i].sort = i;
                    this.lastNum = i
                }   else {
                    clearInterval(FPJSQ);
                    this.start = true
                    FPJSQ = null;
                    this.$emit('toyou')
                }
                this.Cards = OCards
                if(this.sound.type) {
                    this.sound.audioFa.play()
                }
            }, 50)// 发牌 end
            if (!this.start) {
                FPJSQ
                this.start = true
            }//如果没开始的话，入场即进行发牌
        },
        // 钓牌
        Diao() {
            let    Cards = this.Cards,
                 lastNum = this.lastNum,
                    Ping = this.playing,
                //   ZCards = Pan.data.ZCards,
                //   DCards = Cards.filter(item => item.own == "DC"),
                  WCards = Cards.filter(item => item.own == "W"),
                   TCard = Cards.find(item => item.own === 'TCard'), //找到出的这张牌
                    Tnum = TCard.num,            //出牌的数字
                    Dnum = ''                    //要钓的牌的数字

            Dnum = this.toDiaoNum(Tnum)
            if (WCards.length) {
                //找出所有能‘对’的牌
                let DiaoCards = WCards.filter(item => item.num == Dnum)
                if (DiaoCards.length == 0) {
                    // 没有能钓的牌
                    TCard.sort = lastNum + 1
                    TCard.own = 'W'            //把牌移到白区
                    this.lastNum = lastNum + 1 //更新 lastNum
                    if(this.sound.type) {
                        this.sound.audioDiaoOut.play()
                    }
                } else if (DiaoCards.length == 1) {
                    // 只有一张能钓的牌
                    let DiCard = Cards.find(item => item._id == DiaoCards[0]._id)
                    DiCard.own = Ping + '-W'
                    TCard.own = Ping + '-W'
                    DiCard.sort = lastNum + 2
                    TCard.sort = lastNum + 1
                    this.lastNum = lastNum + 2 //更新 lastNum
                    if(this.sound.type) {
                        this.sound.audioDiao.play()
                    }
                } else if (DiaoCards.length > 1) {
                    // 多张牌可以钓
                     TCard.sort = lastNum + 1
                      TCard.own = Ping + '-W'
                    TCard.score = 0            // 价值清零
                    if (Dnum >= 10 && DiaoCards.length === 3) {
                    // 是否为一钓三的情况
                        DiaoCards.forEach((iCard, index) => {
                            iCard.own = Ping + '-W'
                            iCard.score = 0
                            iCard.sort = lastNum + index + 2
                        })
                        this.lastNum = lastNum + 4 //更新 lastNum
                        if(this.sound.type) {
                            this.sound.audioDiaoWin.play()
                        }
                    } else {// 优先钓红，否则钓黑
                        let DiAC = DiaoCards.find(item => item.des == 'A' || item.des == 'C')
                        if(!DiAC) {
                            DiAC = DiaoCards.find(item => item.des == 'B' || item.des == 'D')
                            if(this.sound.type) {
                                this.sound.audioDiao.play()
                            }
                        }
                        let   DiCard = Cards.find(item => item._id == DiAC._id)
                          DiCard.own = Ping + '-W'
                         DiCard.sort = lastNum + 2
                        this.lastNum = lastNum + 2 //更新 lastNum
                    }
                }
            } else {
            // 桌面没牌，直接归白区
                if(this.sound.type) {
                    this.sound.audioDiaoOut.play()
                }
                TCard.sort = this.lastNum + 1
                TCard.own = 'W'
                this.lastNum ++
            }
        },
        Fuan() {
            if(this.sound.type) {
                this.sound.audioFuan.play()
            }
            let DCards = this.DCards,
                FuCard = DCards[DCards.length - 1]
                FuCard.own = 'TCard'
                FuCard.open = true
        },
        // 把数字转化为能钓的相应数字 7 -- 3
        toDiaoNum: function(item) {
            let Dnum
            if (item < 10) {
                Dnum = 10 - item;
            } else if (item == 20) {
                Dnum = 21;
            } else if (item == 21) {
                Dnum = 20;
            } else {
                Dnum = item;
            }
            return Dnum
        },
        // value ranking 自家牌与桌面牌进行比对，找出能对的牌，根据得分排序
        VRanking() {
            let playingId = this.playing
            let WCs = this.WCards     // 桌面的牌
            let MCs = this.Cards.filter(item => item.own == playingId)      // "我"的牌
            let VRlist = [],
                Apot = [], Bpot = [], Cpot = [], Dpot = [], Epot = [], Gpot = [], Fpot = []

            for(let MC of MCs) {
                let MCnum = this.toDiaoNum(MC.num)
                for(let WC of WCs) {
                    if(MCnum == WC.num) {

                        let total = MC.score + WC.score
                        if (MC.score < WC.score) {
                            total += 2
                        }

                        switch (total) {
                            case 40: // 两红公仔
                                Apot.push(MC)
                                break;
                            case 31: // 红 + 黑 公仔
                                Bpot.push(MC)
                                break;
                            case 22: // 两乌公仔
                                Fpot.push(MC)
                                break;
                            case 20: // 两红细仔
                                Cpot.push(MC)
                                break;
                            case 30: // 大小王对
                                Dpot.push(MC)
                                break;
                            case 10: // 两乌细仔
                                Gpot.push(MC)
                                break;
                            default: // 红 + 乌细仔
                                Epot.push(MC)
                                break;
                        }
                        VRlist.push({MCid: MC._id, MCnum: MC.num, WCid: WC._id, score: MC.score + WC.score, MC, WC})
                    }
                }
            }
            if(VRlist.length) {
                for(let VRitem of VRlist) {
                    let  Mnum = VRitem.num,
                        total = VRitem.score
                        // 确定牌
                        // 1、所有的牌都在手或在桌面上 1->3、2->2 3->1
                        // 2、这一对的分数就是剩下分了
                        // 舍牌
                        // 1、B区，手上有三张牌，黑牌占两张时

                        // 配对总分等于 22 时，不能出手……
                    // 判断分数等于剩余分数，即牌被确定
                    if(Mnum > 9 || Mnum == 5) {
                        let Cscore = this.Dog(Mnum, this.Cards)
                        if (Cscore == total) {
                            VRitem.score -= 2
                        }
                    } else if(Mnum == 9 || Mnum == 1) {
                        let Anum = 10 - Mnum
                        let Cscore = this.Dog(Anum, this.Cards)
                        if(Cscore + VRitem.MC.score == total) {
                            VRitem.score -= 2
                        }
                    }
                    // switch (total) {
                    //     case 40: // 两红公仔
                    //         Apot.push(MC)
                    //         break;
                    //     case 31: // 红 + 黑 公仔
                    //         Bpot.push(MC)
                    //         break;
                    //     case 22: // 两乌公仔
                    //         Fpot.push(MC)
                    //         break;
                    //     case 20: // 两红细仔
                    //         Cpot.push(MC)
                    //         break;
                    //     case 30: // 大小王对
                    //         Dpot.push(MC)
                    //         break;
                    //     case 10: // 两乌细仔
                    //         Gpot.push(MC)
                    //         break;
                    //     default: // 红 + 乌细仔
                    //         Epot.push(MC)
                    //         break;
                    // }
                }
                VRlist = this.sortByKey(VRlist, 'score', true)
                return VRlist[0].MC._id
            // if true end --VRlist.length
            } else {
                MCs = this.sortByKey(MCs, 'score', false)
                return MCs[0]._id
            }
            // if(Bpot.length) {
            //     console.log(`B 区： ${Bpot[0]._id}`)
            // }
            // if(Cpot.length) {
            //     console.log(`C 区： ${Cpot[0]._id}`)
            // }
        },
        AIfunction() {

                let AIid = this.VRanking()
                this.Lion(AIid)

                // 执行 AI 代码
            // 理牌
            // AICards.sort(this.sortOut())
        },// AIfunction end
        // 根据数字，查看该牌的得分概率
        ProbA(eventA) {
            let eAnum = eventA.num
            let Cards = this.Cards.filter(item => !RegExp("-w$").test(item.own))
            let PACards, Pa
            // 分区
            if(eAnum == 20 || eAnum == 21) {
                Pa = 1
            } else {
                if (eAnum < 10 && eAnum != 5) {
                    eAnum = 10 - eAnum
                    PACards = Cards.filter(item => item.num == eAnum)
                    Pa = (1/PACards.length).toFixed(2)
                } else {
                    PACards = Cards.filter(item => item.num == eAnum)
                    let eAindex = PACards.indexOf(eventA)
                    PACards.splice(eAindex, 1)
                    Pa = (1/PACards.length).toFixed(2)
                }
            }
            return Pa
        }
    },//methods end
    computed: {
        DCards: function() {
            let DCards = this.cFilter(this.Cards, 'DC')
            let ACnum = this.userids.indexOf(this.firstUser)
            if(ACnum < 2) {
                ACnum = 0
            }
            // DCards[0].open = true
            return DCards
        },
        WCards: function() {
            let WCS = this.cFilter(this.Cards, 'W')
            return this.sortByKey(WCS, 'sort', false)
        },
        TCard: function() {
            return this.cFilter(this.Cards, 'TCard')
        },
        MCards: function() {
            let MCS = this.cFilter(this.Cards, this.userids[1])
            return MCS.sort(this.sortOut())
        },
        MWCards: function() {
            let MWCS = this.cFilter(this.Cards, this.userids[1] + '-W')
            return this.sortByKey(MWCS, 'sort', false)
        },
        PCards: function() {
            return this.cFilter(this.Cards, this.userids[0])
        },
        NCards: function() {
            return this.cFilter(this.Cards, this.userids[2])
        },
        PANuserids: function() {
            let userids = this.userids, u = []
            let WInt     = userids.findIndex(item => item == this.firstUser)
            switch (WInt) {
                case 0:
                    u = [ userids[0], userids[1], userids[2]]
                    break;
                case 1:
                    u = [ userids[1], userids[2], userids[0]]
                    break;
            
                default:
                    u = [ userids[2], userids[0], userids[1]]
                    break;
            }
            return u
        }
    },
    created() {
    },
    mounted() {
        this.wolf()
        this.Jue()
        // plus.screen.lockOrientation("landscape-primary");
        // this.Cards = this.shuffle(Qins)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>
