<template>
    <div class="ModalWCards">
        <div v-if="cards.length">
            <h1><s :class="note"></s></h1>
            <div class="WCs">
                <ul class="WCards"><Card v-for="(item, index) in cards"  :key='"WCardShow" +  index' :Card="item" /></ul>
            </div>
        </div>
        <div class="WCsArea" v-else>
            <h4>{{note}}</h4>
        </div>
    </div>
</template>

<script>
import Card        from './card.vue'
export default {
    name: 'ModalWCards',
    components: {
        Card
    },
    props: {
        MBody: Object
    },
    data() {
        return {
            btnStart: false
        }
    },
    methods: {
        catT() {
            this.$emit('btnClose')
        },
    },
    computed: {
        cards: function() {
            return this.MBody.list
        },
        note: function() {
            return this.MBody.note
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>
