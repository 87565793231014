<template>
  <div :class="['panel', !startsw ? 'startDHD' : '']">
    <div class="P animate__animated" :class="{'current animate__wobble' : playing == users[0]._id}">
        <p class="avatar"><s :style="'background-image: url(' + users[0].avatarUrl + ')'"></s></p>
    </div>
    <div class="M animate__animated" :class="{'current animate__wobble' : playing == users[1]._id}">
        <dl>
            <dt class="avatar"><s :style="'background-image: url(' + users[1].avatarUrl + ')'"></s></dt>
            <dd>
                <h6>
                    <p>{{users[1].score}}</p>
                </h6>
            </dd>
            <dd class="btnStartDHD">
                <b @click="catT" :class="{disable: btnStart}"></b>
            </dd>
        </dl>
    </div>
    <div class="N animate__animated" :class="{'current animate__wobble': playing == users[2]._id}">
        <p class="avatar"><s :style="'background-image: url(' + users[2].avatarUrl + ')'"></s></p>
    </div>
    <div class="toolbar">
        <q @click="catQ('Q')" class="button close"></q>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Panel',
    props: {
        users: {
            type: Array
        },
        playing: String,
        startsw: Boolean,
        scores: Object,
    },
    data() {
        return {
            btnStart: false
        }
    },
    methods: {
        catT() {
            if (!this.btnStart) {
                this.btnStart = true
                this.$emit('dog')
            }
        },
        // 退出
        catQ(spot) {
            if (this.playing == this.users[1]._id) {
                this.$emit('chicken', spot)
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>
