<template>
  <div class="home">
    <dl>
        <dt></dt>
        <dd>
            <div class="info">
                <i class="avatar" :style="'background-image: url(' + users[1].avatarUrl + ')'"></i>
                <div @click="catT('set')">
                    <h6>{{users[1].nickName}}</h6>
                    <p class="score">({{users[1].grade.name}}) {{users[1].score}}</p>
                </div>

            </div>
            <nav>
                <u class="rank" @click="catT('rank')"><s>英雄榜</s></u>
            </nav>
            <aside><b @click="catT('start')" :class="{disable: btnStart}" class="button start"></b></aside>
        </dd>
    </dl>
  </div>
</template>

<script>
export default {
    name: 'Panel',
    props: {
        users: {
            type: Array
        },
        playing: String,
        startsw: Boolean,
        scores: {
            type: Object
        },
    },
    data() {
        return {
            btnStart: false
        }
    },
    methods: {
        catT(tool) {
            if (!this.btnStart) {
                this.btnStart = true
                this.$emit('dog', tool)
            }
        },
    }
}
</script>

