<template>
  <section class="page">
    <b class="tbBar button back" @click="catT()"></b>
    <Rank    v-if="pageType == 'rank'" @bull="bull" :levels="levels" />
    <Level   v-if="pageType == 'grade'" />
    <Rule    v-if="pageType == 'rule'" @bull="bull" />
    <Notice  v-if="pageType == 'note'" @bull="bull" />
    <Set     v-if="pageType == 'set'"  @bull="bull" @wasp="wasp" :options="optionsOrigin" />
    <Msg     v-if="pageType == 'msg'"  @bull="bull" />
  </section>
</template>

<script>
import Rank   from './subpage/rank.vue'
import Level  from './subpage/level.vue'
import Rule   from './subpage/rule.vue'
import Notice from './subpage/notice.vue'
import Msg    from './subpage/msg.vue'
import Set    from './subpage/set.vue'
export default {
    name: 'Page',
    props: {
        pageData: {
            type: Object
        }
    },
    components: {
        Level, Rank, Rule, Notice, Msg, Set
    },
    data() {
        return {
            pageSW: '',      // 记录由哪个页面过来的，“返回”哪个页面
            btnStart: false,
            levels: [],
            optionsOrigin: Object
        }
    },
    methods: {
        // 返回按钮--若pageSW有值，即跳转到pageSW，否则把动作转给app
        catT(dog) {
            if (this.pageSW) {
                this.pageData.type = this.pageSW
                this.pageSW = dog
            } else {
                this.$emit('dog')
            }
        },
        // page 里的操作，传过来的变量，若是数组，即带有返回页（如在“升级规则”点返回，将跳转回“英雄榜”）
        bull(dog) {
            if (Array.isArray(dog)) {
                this.pageData.type = dog[0]
                this.pageSW = dog[1]
            } else if (dog == 'set') {
                this.optionsOrigin = Object.assign({}, this.pageData.body)
                this.pageData.type = dog
            } else {
                this.pageData.type = dog
            }
        },
        // 获取排行榜表单
        cattle() {
            let url = this.pageData.body.api
            this.axios({
                method: 'get',
                url: url + 'ranking',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(res => {
                this.levels = res.data
            }).catch(err=>{
                console.log(err)
            })
        },
        // 来自设置(set)的保存操作
        wasp(bird) {
            this.$emit('bird', bird)
        }
    },
    computed: {
        pageType: function() {
            return this.pageData.type
        },
    },
    created() {
        // 进入普通页面时，先根据打开类型进行拦截。提供不同的内容
        if (this.pageData.type == 'rank') {
            this.cattle()
        } else if (this.pageData.type == 'set') {
            // 进入“设置”前，先把options数据copy一份，之后的操作以optionsOrigin为准
            this.optionsOrigin = Object.assign({}, this.pageData.body)
        }
    },
    mounted() {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>
