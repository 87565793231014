<template>
  <article class="rule">
    <dl class="tab">
        <dd @click="cowT('set')" class="TBset"></dd>
        <dd @click="cowT('note')" class="TBnote"></dd>
        <dt @click="cowT('rule')" class="TBrule"></dt>
        <dd @click="cowT('msg')" class="TBcallback"></dd>
    </dl>
    <div>
        <h4>出牌规则：</h4>
<p>1、准备：选出头家，头家的顺时针玩家（左手方）为尾家，逆时针玩家（右手方）为下家；</p>
<p>2、切牌：把所有牌叠在一起，由尾家拿起一定数量的牌后，手所拿那部分牌的最后一张牌为尾家的底牌，保证底牌不动的情况下，和桌面上所剩下牌叠一起（正确操作是先把切牌后手上所持的那部分牌放桌面上，再拿桌面剩下的牌往此部分牌上盖），底牌——尾家可随时翻看，其他人无权查看。</p>
<p>3、发牌：由头家开始逆时针依次往每个玩家前面发一张牌，到尾家为一轮，直到第8轮（此时每人 8 张牌），之后往桌面上反白（亮出数字那面） 6 张牌，其他牌盖住放中间。（补充：因为规定钓牌动作需要由玩家或盖着的牌钓起桌面上反白的牌；因此，当发完牌后桌面上反白的牌里同时出现了大、小王或 4 张 10、J、Q、K 相同的牌时，该牌（2张或4张）都归尾家，因为无法产生钓牌动作）</p>
<p>4、出牌：头家先出牌，根据下方钓牌规则出 1 张牌钓起桌面上反白的牌，接着由桌面上盖着的牌里翻出第一张，同样与桌面上反白的牌进行相钓，此时该玩家出牌程序完成，若此两个钓牌动作钓有牌皆归出牌者所有。再到逆时针玩家（即右手方）出牌，尾家跟后。之后轮回头家出牌，最终 8 轮（所有玩家手上的牌、桌面上盖着的牌、反白的牌都归各玩家）结束此局钓牌。</p>
        <h4>钓牌规则：</h4>
<p>牌面 10 及以上的牌（10、J、Q、K、大、小王）同类相钓，如 10 钓 10、J 钓 J、大、小王互钓。</p>
<p>牌面 10 以下的（1、2、3、4、5、6、7、8、9）两牌相加为 10 的，可相钓。如：3 钓 7，8 钓 2 等</p>
        <h4>算分规则：</h4>
<p>以红色牌（方块、红桃）算得分。</p>
<p>2、3、4、6、7、8 这些牌按数字本身算分，如方块 2，即两分，红桃 2，也是两分，其他的类推；</p>
<p>A、5、9 每张按 10 分算，如：红桃 A 钓 方块 9，得 20 分，其他类推。</p>
<p>J、Q、K、大王（King） 都算 10 分。</p>
<p>每个玩家 70 分保本，少或多于 70 分的差为所输或赢的分数。如头家得分为 80 分，即他赢了 10 分，而尾家 只有 65 分，即他输了 5 分。</p>
        <h4>钓牌技巧：</h4>
<p>因为钓到红牌才算分，此牌不管是原来自己手上持有或桌面上盖着、反白的牌。因此，尽可能的用手上的红牌去钓底下的红牌，而且 1、5、9、10、J、Q、K 是按 10 分算的，所以这些的优先程度要更高。如用手上的红 10 去钓底下的 红 10（若刚好手上及桌面反白的牌刚好有这两者的话），这样就一下子得20分。若运气暴膨，此时由桌面上盖着的牌里又同样翻出一张红 （1、5、9、10、J、Q、K）钓起桌面上反白的牌，那玩家的此次出牌共获得40分，此为每次出牌可能的最高得分。</p>
    </div>
  </article>
</template>

<script>
export default {
    name: 'Level',
    data() {
        return {
            levels: [
                { Lv: 1,  name: "列兵",     score: '﹤1200'},
                { Lv: 2,  name: "下士",     score: 1200},
                { Lv: 3,  name: "中士",     score: 1500},
                { Lv: 4,  name: "上士",     score: 1900},
                { Lv: 5,  name: "少尉",     score: 2400},
                { Lv: 6,  name: "中尉",     score: 3000},
                { Lv: 7,  name: "上尉",     score: 3700},
                { Lv: 8,  name: "少校",     score: 4500},
                { Lv: 9,  name: "中校",     score: 5400},
                { Lv: 10, name: "上校",     score: 6400},
                { Lv: 11, name: "大校",     score: 7500},
                { Lv: 12, name: "少将",     score: 10000},
                { Lv: 13, name: "中将",     score: 12900},
                { Lv: 14, name: "上将",     score: 16200},
                { Lv: 15, name: "荣誉上将",  score: 19900}
            ]
        }
    },
    methods: {
        cowT(dog) {
            this.$emit('bull', dog)
        },
    }
}
</script>

