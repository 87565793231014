<template>
  <article class="note">
    <dl class="tab">
        <dd @click="cowT('set')" class="TBset"></dd>
        <dt @click="cowT('note')" class="TBnote"></dt>
        <dd @click="cowT('rule')" class="TBrule"></dd>
        <dd @click="cowT('msg')" class="TBcallback"></dd>
    </dl>
    <div>
        <h4>谢谢大家的支持：</h4>
        <p>最近因为更新频繁，导致时有未测试到的问题出现。</p>
        <p>也因此导致部分用户无法使用、无法显示得分等。</p>
        <p>若之后再碰到类似情况，为了不影响你的使用，欢迎通过下述方式，告诉我们情况，我们将尽快修复。</p>
        <p>步骤一：在任何一个页面右上角的“…”</p>
        <p><img src="/images/IMG_28F1.jpg" alt=""></p>
        <p>步骤二：在弹出的页面里找到——>反馈与投诉（配图为苹果截屏，安卓可能有所出入）</p>
        <p><img src="/images/IMG_28F2.jpg" alt=""></p>
        <p>步骤三：找到——>功能异常与产品建议（配图为苹果截屏，安卓可能有所出入）</p>
        <p><img src="/images/IMG_28F3.jpg" alt=""></p>
        <p>步骤四：填写你的碰到的问题，如果能有截图更好。我们会尽快解决问题。</p>
        <h5>最后再一次感谢你使用与理解！</h5>
    </div>
  </article>
</template>

<script>
export default {
    name: 'Level',
    data() {
        return {
            notice: [
            ]
        }
    },
    methods: {
        cowT(dog) {
            this.$emit('bull', dog)
        },
    }
}
</script>

