<template>
  <article class="pageSet">
    <dl class="tab">
        <dt @click="cowT('set')"  class="TBset"></dt>
        <dd @click="cowT('note')" class="TBnote"></dd>
        <dd @click="cowT('rule')" class="TBrule"></dd>
        <dd @click="cowT('msg')"  class="TBcallback"></dd>
    </dl>
    <div>
        <section>
            <h4><i>声音：</i>
                <p class="FradiaBtn" :class="{'open': options.sound}" @click="fish('sound')">
                    <s>关闭</s>
                    <b>开启</b>
                </p>
            </h4>
            <span>注意:若打开后出现卡顿情况，建议关闭</span>
        </section>
        <section>
            <h4><i>速度：</i>
                <p class="FcheckboxBtn">
                    <u @click="fish(2000)" :class="{'current': options.speed == 2000}">慢</u>
                    <u @click="fish(1000)" :class="{'current': options.speed == 1000}">中</u>
                    <u @click="fish(500)" :class="{'current': options.speed == 500}">快</u>
                </p>
            </h4>
            <span>调节电脑出牌的速度</span>
        </section>
        <q><b @click="bird()" class="button save" :class="{'dis': btnType}"></b></q>
    </div>
  </article>
</template>

<script>
export default {
    name: 'Set',
    props: {
        options: {
            type: Object
        },
    },
    data() {
        return {
            btnType: true,
        }
    },
    methods: {
        cowT(dog) {
            this.$emit('bull', dog)
        },
        fish(dog) {
            this.btnType = false
            if (dog == 'sound') {
                this.options.sound = !this.options.sound
            } else {
                this.options.speed = dog
            }
        },
        bird() {
            this.btnType = true
            this.$emit('wasp', this.options)
        }
    },
    computed: {
    },
    mounted() {
    },

}
</script>

