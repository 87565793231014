<template>
  <article class="levels">
    <h1></h1>
    <dl>
        <dt>钓红点一共分15个段位，分别是：列兵、下士、中士、上士、少尉、中尉、上尉、少校、中校、上校、大校、少将、中将、上将、荣誉上将。荣誉上将，需要积分达标且排名前50名的玩家才可获得。</dt>
        <dd>
            <p v-for="(item, index) in levels"  :key='"Lv" +  index' :class="'Level' + item.Lv"><s>{{item.name}}</s><i><s>{{item.score}}</s></i></p>
        </dd>
    </dl>
  </article>
</template>

<script>
export default {
    name: 'Level',
    data() {
        return {
            levels: [
                { Lv: 1,  name: "列兵",     score: '﹤1200'},
                { Lv: 2,  name: "下士",     score: 1200},
                { Lv: 3,  name: "中士",     score: 1500},
                { Lv: 4,  name: "上士",     score: 1900},
                { Lv: 5,  name: "少尉",     score: 2400},
                { Lv: 6,  name: "中尉",     score: 3000},
                { Lv: 7,  name: "上尉",     score: 3700},
                { Lv: 8,  name: "少校",     score: 4500},
                { Lv: 9,  name: "中校",     score: 5400},
                { Lv: 10, name: "上校",     score: 6400},
                { Lv: 11, name: "大校",     score: 7500},
                { Lv: 12, name: "少将",     score: 10000},
                { Lv: 13, name: "中将",     score: 12900},
                { Lv: 14, name: "上将",     score: 16200},
                { Lv: 15, name: "荣誉上将",  score: 19900}
            ]
        }
    },
    methods: {
        cowT() {
            this.$emit('bull')
        },
    }
}
</script>

