<template>
        <li :class="[Card._id, Card.own, Card.open ? Card.des: '', {'open' : Card.open}, checked == Card._id ? 'checked': '']" @click="thisCard(Card._id)"><i>{{Card.open ? Card.name: ''}}</i></li>
</template>

<script>
export default {
    name: 'Card',
    props: {
        Card: {
            type: Object
        },
        checked: String,
    },
    data() {
        return {
            name: '',
            des: '',
            Cid: '',
            Bide: '',
        }
    },
    methods: {
        thisCard(cid) {
                this.$emit('sendcard', cid)
        }
    },
    computed: {},
    mounted() {
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
