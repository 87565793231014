<template>
  <div class="pageEnd" :class="gradeSW == '' ? endState : gradeSW">
    <h1 class="animate__animated animate__tada"></h1>
    <article>
        <ul>
            <li class="P animate__animated" :class="[gradeSW == '' ? 'animate__fadeInRight' : 'animate__fadeOutLeftBig', {current : playing == users[0]._id}]">
                <dl>
                    <dt class="avatar" :style="'background-image: url(' + users[0].avatarUrl + ')'"></dt>
                    <dd>
                        <h6><s>{{users[0].nickName}}</s></h6>
                        <h5><b>{{scores.Pscore > 0 ? "+" + scores.Pscore : scores.Pscore}}</b></h5>
                    </dd>
                </dl>
            </li>
            <li class="M animate__animated" style="animation-delay: 200ms" :class="[gradeSW == '' ? 'animate__zoomIn' : 'animate__fadeOutLeftBig', {current : playing == users[1]._id}]">
                <dl>
                    <dt class="avatar" :style="'background-image: url(' + users[1].avatarUrl + ')'"></dt>
                    <dd>
                        <h6><s>{{users[1].nickName}}</s></h6>
                        <h3><b>{{scores.Mscore > 0 ? "+" + scores.Mscore : scores.Mscore}}</b></h3>
                        <div class="progress">
                            <s>{{users[1].grade.name}}</s>
                            <h4 v-if="users[1].grade"><i :style="'width: ' + progressVal + '%'"></i><samp>{{users[1].score}} / {{users[1].grade.NScore}}</samp></h4>
                            <s>{{levels[users[1].grade.Lv].name}}</s>
                        </div>
                    </dd>
                </dl>
            </li>
            <li class="N animate__animated" style="animation-delay: 100ms" :class="[gradeSW == '' ? 'animate__fadeInLeft' : 'animate__fadeOutLeftBig', {current : playing == users[2]._id}]">
                <dl>
                    <dt class="avatar" :style="'background-image: url(' + users[2].avatarUrl + ')'"></dt>
                    <dd>
                        <h6><s>{{users[2].nickName}}</s></h6>
                        <h5><b>{{scores.Nscore > 0 ? "+" + scores.Nscore : scores.Nscore}}</b></h5>
                    </dd>
                </dl>
            </li>
        </ul>
        <ol v-if="gradeSW != ''">
            <li class="animate__animated" :class="gradeSW == '' ? '' : 'animate__slideInRight'">
                <h2><i>{{users[1].grade.Lv - 1}}</i></h2>
                <p>之前是--{{levels[users[1].grade.Lv - 2].name}}</p>
            </li>
            <li class="animate__animated" style="animation-delay: 100ms" :class="gradeSW == '' ? '' : 'animate__zoomIn'">
                <u :class="'lv' + users[1].grade.Lv"></u>
                <h4><i>{{users[1].nickName}}</i></h4>
                <p>真是牌艺高超啊！</p>
            </li>
            <li class="animate__animated" :class="gradeSW == '' ? '' : 'animate__slideInRight'">
                <h3><i>{{users[1].grade.Lv}}</i></h3>
                <p>荣升为--{{users[1].grade.name}}</p>
            </li>
        </ol>
    </article>
    <aside>
        <b @click="catT('Q')" :class="{disable: btnStart}" class="button logout animate__animated animate__bounceInLeft" style="animation-delay: 500ms"></b>
        <b @click="catT('R')" :class="{disable: btnStart}" class="button redo animate__animated animate__bounceInLeft" style="animation-delay: 600ms"></b>
    </aside>
  </div>
</template>

<script>
export default {
    name: 'End',
    props: {
        users: {
            type: Array
        },
        playing: String,
        showSW: String,
        scores: {
            type: Object
        }
    },
    data() {
        return {
            gradeSW: '',
            btnStart: false,
            levels: [
                { Lv: 1,  name: "列兵",     score: '﹤1200'},
                { Lv: 2,  name: "下士",     score: 1200},
                { Lv: 3,  name: "中士",     score: 1500},
                { Lv: 4,  name: "上士",     score: 1900},
                { Lv: 5,  name: "少尉",     score: 2400},
                { Lv: 6,  name: "中尉",     score: 3000},
                { Lv: 7,  name: "上尉",     score: 3700},
                { Lv: 8,  name: "少校",     score: 4500},
                { Lv: 9,  name: "中校",     score: 5400},
                { Lv: 10, name: "上校",     score: 6400},
                { Lv: 11, name: "大校",     score: 7500},
                { Lv: 12, name: "少将",     score: 10000},
                { Lv: 13, name: "中将",     score: 12900},
                { Lv: 14, name: "上将",     score: 16200},
                { Lv: 15, name: "荣誉上将",  score: 19900},
                { Lv: 16, name: "--",  score: 29900}
            ]
        }
    },
    methods: {
        catT(dog) {
            if (!this.btnStart) {
                this.btnStart = true
                this.$emit('dog', dog)
            }
        },
        cowM() {
            setTimeout(() => {
                this.gradeSW = this.scores.state
            }, 2000)
        },
    },
    computed: {
        endState: function() {
            let score = this.scores.Mscore
            if (score > 0) {
                return 'win'
            } else if (score < 0) {
                return 'loss'
            } else {
                return 'draw'
            }
        },
        progressVal: function() {
            let scoreO = this.users[1].grade.score,
                scoreN = this.users[1].grade.NScore,
                score  = this.users[1].score,
                count
            count = (score - scoreO) / (scoreN - scoreO) * 100
            return count
        }
    },
    mounted() {
        this.cowM()
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>
