<template>
  <article class="rankingList">
    <b class="button help" @click="cowT(['grade', 'rank'])"></b>
    <h1></h1>
    <dl>
        <dt></dt>
        <dd>
            <ul :style="'width: ' + (280 * levels.lenght) + 'rem'">
                <li v-for="(item, index) in levels" :key='"Lv" + index' :class="'rankA' + (index + 1)"><h2><span>{{index + 1}}</span></h2><cite :style="'background-image: url(' + item.avatarUrl + ')'"></cite><h3>{{item.nickName}}</h3><i><s>{{item.score}}</s></i></li>
            </ul>
        </dd>
    </dl>
  </article>
</template>

<script>
export default {
    name: 'Rank',
    props: {
        levels: {
            type: Array
        }
    },
    data() {
        return {
        }
    },
    methods: {
        catT() {
            if (!this.btnStart) {
                this.btnStart = true
                this.$emit('dog')
            }
        },
        cowT(cat) {
            this.$emit('bull', cat)
        },
    }
}
</script>

