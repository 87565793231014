<template>
    <dl class="loadingArea">
        <dt></dt>
        <dd>
            <ol class="loading">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ol>
            <p>{{tip}}</p>
            <h6><b :style="'width:' + size + '%'"></b></h6>
        </dd>
    </dl>
</template>

<script>
export default {
    name: 'Loading',
    props: {
        loadcaps: {
            type: Object
        }
    },
    data() {
        return {
            tip: '请等等哦！'
        }
    },
    methods: {
        // 发牌
        wolf() {
            let tips = [
                    "小休息会，网速有点慢。",
                    "慢慢来，应该比较快了。",
                    "先食杯茶，快了",
                    "哦，你不会没网了吧？",
                    "抱歉，估计系统出问题了。",
                    '请等等哦！'
                ], 
                i = 0
            let sayTip = setInterval(() => {
                this.tip = tips[i]
                i++
                if (i == tips.length) {
                    i = 0
                }
            }, 5000)// 发牌 end
            sayTip
        },
    },
    computed: {
        size: function() {
            let total = this.loadcaps.Rtotal,
                size = this.loadcaps.Rsize,
                loadcap = size / total * 100
            return loadcap
        }
    },
    mounted() {
        this.wolf()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
</style>
